<template>
  <div class="login-container">
    <div class="sm:w-1/2 xl:w-1/3 login-card">
      <div>
        <p class="login-card-header">Carbon</p>
        <p class="text-large">ระบบบริหารจัดการวิชาโครงงาน สหกิจศึกษา และศึกษาอิสระ</p>
        <hr class="mx-4 mt-4">
        <form v-on:submit.prevent="login">
          <div class="row mt-8">
            <div class="login-icon-div"><svg xmlns="http://www.w3.org/2000/svg" class="login-icon" viewBox="0 0 20 20" fill="gray"><path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clip-rule="evenodd" /></svg></div>
            <input v-model="username" type="text" placeholder="Username" class="login-form-div">
          </div>
          <div class="row mt-8">
            <div class="login-icon-div"><svg xmlns="http://www.w3.org/2000/svg" class="login-icon" viewBox="0 0 20 20" fill="gray"><path fill-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clip-rule="evenodd" /></svg></div>
            <input v-model="password" type="password" placeholder="Password" class="login-form-div">
          </div>
          <hr class="mx-4 mt-8">
          <button type="submit" value="submit" class="login-button">เข้าสู่ระบบ</button>
        </form>
      </div>
      <h2 v-if="!success" class="text-error -mt-4" style="margin-left: 0">{{ error }}</h2>
    </div>
  </div>
</template>

<script>
export default {
  name: "LogIn",
  data () {
    return {
      success: true,
      error: "",
      username: "",
      password: "",
    }
  },
  async created() {
    document.title = "เข้าสู่ระบบ | Carbon"
  },
  methods: {
    async login() {
      const data = { username: this.username, password: this.password }
      await this.$store.dispatch("login", data)
        .then(response => this.success = response)
        .catch(error => { this.success = false,
          this.error = error == "400" ? "ไม่พบบัญชีผู้ใช้ในระบบ กรุณาติดต่อเจ้าหน้าที่ผู้ดูแลรายวิชา" : error == "404" ? "ชื่อผู้ใช้งานหรือรหัสผ่านไม่ถูกต้อง" : ""
        }
      )
      if (this.success) {
        this.$router.push(this.$route.query.redirect || "/")
      }
    }
  }
}
</script>
